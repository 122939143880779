const HTTP_STATUS =  {
    CODE_400: 400,
    CODE_403: 403,
    CODE_404: 404,
    CODE_408: 408,
    CODE_500: 500,
    MSG_OFFLINE: 'Network Error'
}

const CARD_TYPE =  {
    VISA: 'VISA',
    MASTERCARD: 'MASTERCARD',
    AMEX: 'AMEX',
    DISCOVER: 'DISCOVER',
    DINERS: 'DINERS',
    NONE: 'NONE',
}

export { HTTP_STATUS, CARD_TYPE };