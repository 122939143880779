import React from 'react';
import SucessPayment from '../../img/icono-success-animated.svg';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AutoexpresoNotifier from '../FinesPanel/AutoExpreso/Notification';

const PaymentConfirmation = (props) => {
  const gotoHome = () => {
    props.confirmationCallback();
    props.history.replace('/');
  }

  const checkAutoexpreso = function () {
    const totals = props.totals.qtty;
    return totals.autoexpreso > 0;
  };

  return (
    <div className="paymentConfirmation-container">

      <Grid container spacing={5} className="content-wrapper medium-width align-center">
        <Grid item xs className="mt-2 ptblr">
          <Paper className="ptblr mod-confirmation paymentForm">
            <div className="primary-title">Pago Aceptado</div>
            <div>Has pagado las multas seleccionadas!</div>
            <div className="App-link"><strong>Código de transacción: {props.transactionCode}</strong></div>
            <div><img src={SucessPayment} alt="steps-dtop" /></div>
            <p>
              La transacción realizada fue aprobada. Estará recibiendo un correo electrónico a la dirección <br />
              provista con la confirmación de pago de su(s) multa(s). Favor de revisar buzón de entrada o  <br />
              buzón de correo no deseado.
            </p>
            { checkAutoexpreso() ? <AutoexpresoNotifier /> : null }
            <p>
              <Button className="special-padding" onClick={gotoHome} type="button" variant="contained" color="primary">
                Finalizar
              </Button>
            </p>
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}

export default PaymentConfirmation;
