import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PaymentService from "../../services/PaymentService";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import PaymentModal from './PaymentModal/PaymentModal';

import logoGris from '../../img/logo-gris.png';
import logoVisa from '../../img/logo-visa.png';
import logoDiscover from '../../img/logo-discover.jpg';
import logoAmex from '../../img/logo-amex.png';
import logoMasterCard from '../../img/logo-mastercard.png';
import logoDiners from '../../img/logo-diners.jpg';
import logoCvv from '../../img/logo-cvv.png';
import logoColecturia from '../../img/logo-colecturia.png';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import "moment/locale/es";

import { useFormik, Field, FormikProvider } from 'formik';
import * as yup from 'yup';

import './PaymentForms.css';
import ErrorModal from '../../shared/ErrorModal/ErrorModal';
import NoInternetModal from '../../shared/NoInternetModal/NoInternetModal';
import * as Utils from '../../shared/Utils';
import { HTTP_STATUS, CARD_TYPE } from '../../shared/Constants/Constants';

const localeMap = {
  en: enLocale,
  es: esLocale
};

const PaymentForm = (props) => {

  const validationSchema = yup.object({
    email: yup
      .string('Ingrese su Email')
      .email('Formato de Email no es valido')
      .required('Email es requerido'),
    billAddress: yup
      .string('Ingrese su BillAddress')
      .required('BillAddress es requerido')
      .max(50, 'BillAddress debe ser maximo de 50 caracteres'),
    billCountryAddress: yup
      .string('Ingrese su BillCountryAddress')
      .required('BillCountryAddress es requerido')
      .max(50, 'BillCountryAddress debe ser maximo de 50 caracteres'),
    stateBillAddress: yup
      .string('Ingrese su stateBillAddress')
      .required('stateBillAddress es requerido')
      .max(2, 'stateBillAddress debe ser maximo de 2 caracteres'),
    cityBillAddress: yup
      .string('Ingrese su cityBillAddress')
      .required('cityBillAddress es requerido')
      .max(32, 'cityBillAddress debe ser maximo de 50 caracteres'),
    zipcodeBillAddress: yup
      .string('Ingrese su zipcodeBillAddress')
      .required('zipcodeBillAddress es requerido')
      .max(5, 'zipcodeBillAddress debe ser maximo de 5 digitos'),
    checked: yup
      .boolean('Aceptar Verificación')
      .oneOf([true], 'Verificación es requerido'),
    cardHolder: yup
      .string('Ingrese su CardHolder')
      .required('CardHolder es requerido')
      .max(32, 'CardHolder debe ser maximo de 32 caracteres'),
    cardNumber: yup
      .string('Ingrese su CardNumber')
      .required('CardNumber es requerido')
      .min(13, 'CardNumber debe ser minimo de 13 digitos')
      .max(16, 'CardNumber debe ser minimo de 16 digitos'),
    cvv: yup
      .string('Ingrese su CVV')
      .required('CVV es requerido')
      .min(3, 'CVV debe ser minimo de 3 digitos')
      .max(4, 'CVV debe ser minimo de 4 digitos'),
    cardExpirationDate: yup
      .string('Ingrese su cardExpirationDate')
      .required('cardExpirationDate es requerido'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      billAddress: '',
      billCountryAddress: '',
      stateBillAddress: '',
      cityBillAddress: '',
      zipcodeBillAddress: '',
      checked: false,
      cardHolder: '',
      cardNumber: '',
      cvv: '',
      cardExpirationDate: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const paymentData = { ...values, cardExpirationDate: formatDate(values.cardExpirationDate), amount: props.finalSelection.totalAmount.toFixed(2), finesId: props.finalSelection.finesId }
      setProperty({ loading: true, errors: '', clear: false, colecturia: false });
      PaymentService.doPayment(paymentData)
        .then(res => {
          if (res.data.successful) {
            setProperty({ loading: false, errors: '', clear: false, colecturia: false });
            props.transactionCallback(res.data.transactionId);
            props.finesCallback(props.finalSelection, 'confirmation');
            props.history.push('/confirmacion');
          } else {
            setProperty({
              errors: res.data.message,
              loading: false,
              clear: false,
              colecturia: true
            });
            showModal(true);
          }
        }).catch(err => {

          if (err.toJSON().message === HTTP_STATUS.MSG_OFFLINE) {
            setNoInternetModalState(true);
            return;
          }

          if (err.response.status === HTTP_STATUS.CODE_400) {
            setProperty({
              errors: 'No se acepto la solicitud de pago debido a que no cumplió con todas las validaciones',
              loading: false,
              clear: false,
              colecturia: false
            })
            showModal(true);
          } else if (err.response.status === HTTP_STATUS.CODE_403 || err.response.status === HTTP_STATUS.CODE_408) {
            setProperty({
              errors: 'La sesión ha expirado, se le redirigirá a la pantalla de inicio.',
              loading: false,
              clear: true,
              colecturia: false
            })
            showModal(true);
          } else {
            showErrorModal(true);
          }

        })
    },
  });

  const formatDate = (value) => moment(new Date(value)).format('MM/YYYY');

  const [modalState, setModalState] = React.useState(false);

  const showModal = (modalOpen) => {
    setModalState(modalOpen);
  };

  const [errorModalState, setErrorModalState] = React.useState(false);
  const [noInternetModalState, setNoInternetModalState] = React.useState(false);
  const showErrorModal = (modalOpen) => {
    setErrorModalState(modalOpen);
  };

  const [property, setProperty] = React.useState({
    loading: false,
    errors: '',
    clear: false,
    colecturia: false,
  });

  const gotoHome = () => {
    showErrorModal(false);
    props.clearSesion();
    props.history.push('/');
  }

  const [cardTypeImg, setcardTypeImg] = React.useState(logoGris);

  const BlurCreditCardType = (event) => {
    const result = Utils.detectCreditCardType(event.target.value);
    switch (result) {
      case CARD_TYPE.VISA:
        setcardTypeImg(logoVisa);
        break;
      case CARD_TYPE.MASTERCARD:
        setcardTypeImg(logoMasterCard);
        break;
      case CARD_TYPE.AMEX:
        setcardTypeImg(logoAmex);
        break;
      case CARD_TYPE.DISCOVER:
        setcardTypeImg(logoDiscover);
        break;
      case CARD_TYPE.DINERS:
        setcardTypeImg(logoDiners);
        break;
      default:
        setcardTypeImg(logoGris);
    }
  }

  return (
    <div className="paymentForm-container">

      <form onSubmit={formik.handleSubmit} className="paymentForm-form">
        <Grid container spacing={5} className="content-wrapper pb-content">
          <Grid item xs={6} className="mt-2 ptblr">
            {props.currentStepper}
            <div className="primary-title">Información de Usuario</div>

            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Correo electrónico"
                  variant="outlined"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="billAddress"
                  label="Dirección"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  value={formik.values.billAddress}
                  onChange={formik.handleChange}
                  onKeyPress={Utils.handleOnKeyPressOnlyCertainSpecialLetters}
                  error={formik.touched.billAddress && Boolean(formik.errors.billAddress)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="cityBillAddress"
                  label="Ciudad"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 32 }}
                  value={formik.values.cityBillAddress}
                  onChange={formik.handleChange}
                  onKeyPress={Utils.handleOnKeyPressOnlyCertainSpecialLetters}
                  error={formik.touched.cityBillAddress && Boolean(formik.errors.cityBillAddress)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="stateBillAddress"
                  label="Estado/Jurisdicción"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  value={formik.values.stateBillAddress}
                  onChange={formik.handleChange}
                  onKeyPress={Utils.handleOnKeyPressOnlyLetters}
                  error={formik.touched.stateBillAddress && Boolean(formik.errors.stateBillAddress)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="billCountryAddress"
                  label="País"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  value={formik.values.billCountryAddress}
                  onChange={formik.handleChange}
                  onKeyPress={Utils.handleOnKeyPressOnlyLetters}
                  error={formik.touched.billCountryAddress && Boolean(formik.errors.billCountryAddress)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="zipcodeBillAddress"
                  label="Código Postal"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  value={formik.values.zipcodeBillAddress}
                  onChange={formik.handleChange}
                  onKeyPress={Utils.handleOnKeyPressOnlyNumbers}
                  error={formik.touched.zipcodeBillAddress && Boolean(formik.errors.zipcodeBillAddress)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormikProvider value={formik}>
                  <Field name="checked"
                    component={({
                      field,
                      form: { touched, errors },
                      ...props
                    }) => (
                      <p className={'text-conditions ' + (errors.checked && touched.checked ? 'color-error' : '')}>
                        <Checkbox checked={field.value} {...field} {...props} />
                        Verifique que la información provista esté correcta antes de proseguir. Gracias.
                      </p>
                    )} />
                </FormikProvider>
              </Grid>

            </Grid>

          </Grid>

          <Grid item xs={6} className="mt-2">
            <Paper className="ptblr">
              <div className="primary-title">Información para el Pago</div>

              <Grid container alignItems="center" className="bg-LightGray ptblr-2">
                <Grid item xs={12} sm={6}>
                  <div className="txt-fines">
                    <strong>Cantidad a pagar</strong>
                    <p>por ({props.finalSelection.selectedCounter}) multa{(props.finalSelection.selectedCounter === 1) ? '' : 's'} seleccionada{(props.finalSelection.selectedCounter === 1) ? '' : 's'}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="align-right">
                  <span className="total-payment">${props.finalSelection.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="mt-2">
                <Grid item xs={12} md={12} lg={7}>
                  <TextField
                    name="cardHolder"
                    label="Titular de la tarjeta"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 32 }}
                    value={formik.values.cardHolder}
                    onChange={formik.handleChange}
                    onKeyPress={Utils.handleOnKeyPressOnlyNumbersLetters}
                    error={formik.touched.cardHolder && Boolean(formik.errors.cardHolder)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Fecha de Vencimiento"
                      format="MM/yyyy"
                      placeholder="mm/yyyy"
                      fullWidth
                      name="cardExpirationDate"
                      views={["month", "year"]}
                      value={formik.values.cardExpirationDate}
                      onChange={val => formik.setFieldValue('cardExpirationDate', val)}
                      error={formik.touched.cardExpirationDate && Boolean(formik.errors.cardExpirationDate)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={8} md={7} lg={6}>
                  <TextField
                    name="cardNumber"
                    label="Número de la tarjeta"
                    variant="outlined"
                    fullWidth
                    value={formik.values.cardNumber}
                    inputProps={{ maxLength: 16 }}
                    onChange={formik.handleChange}
                    onKeyPress={Utils.handleOnKeyPressOnlyNumbers}
                    onBlur={BlurCreditCardType}
                    error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
                  />
                </Grid>

                <Grid item xs={4} md={5} lg={2} className="paymentForm-center">
                  <img className="paymentForm-card" src={cardTypeImg} alt="logo tarjeta" />
                </Grid>

                <Grid item xs={8} md={7} lg={2}>
                  <TextField
                    name="cvv"
                    label="CVV"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    value={formik.values.cvv}
                    onChange={formik.handleChange}
                    onKeyPress={Utils.handleOnKeyPressOnlyNumbers}
                    error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                  />
                </Grid>

                <Grid item xs={4} md={5} lg={2} className="paymentForm-center">
                  <img className="paymentForm-card" src={logoCvv} alt="logo cvv" />
                </Grid>

              </Grid>

              <Grid container alignItems="flex-end" className="paymentForm mt-3">
                <Grid item xs={12} className="align-right">
                  <Button className="special-padding" type="submit" variant="contained" color="primary" disabled={!formik.isValid || (formik.isValid && property.loading)}>
                    Confirmar pago
                  </Button>
                </Grid>
              </Grid>

            </Paper>
          </Grid>

          <div className="paymentForm-loading">
            <label className="paymentForm-text">Pago procesado por:</label>
            <img className="paymentForm-colecturia" src={logoColecturia} alt="logo colecturia" />
          </div>
        </Grid>
      </form>

      <PaymentModal totals={props.totals} colecturia={property.colecturia} modalState={modalState} description={property.errors} clear={property.clear} gotoHome={gotoHome} parentCallback={showModal} />
      <ErrorModal modalState={errorModalState} parentCallback={gotoHome} />
      <NoInternetModal modalState={noInternetModalState} parentCallback={gotoHome} />
    </div>
  );

};

export default PaymentForm;
