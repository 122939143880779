import React from 'react';
import { Home, Facebook, Twitter, YouTube } from '@material-ui/icons';
import './footer.css';
import dtopLogoFooter from '../../img/AF-PagoFacil-logo_GreenPill.png';

class Footer extends React.Component {
    render() {
        return (
            <div className="main-footer">
                <div className="col-auto">
                    <p> < Home className="icon-footer" /> < a href="https://dtop.pr.gov/"
                        target="_blank" rel="noreferrer"> DTOP - Departamento de Transportación y Obras Públicas </a></p>
                    <p> Box 41269, Minillas Station San Juan, PR 00940 - 1269 </p>
                    <ul className="list">
                        <li> 787 - 722 - 2929 </li>
                        <li> DISCO 787 - 294 - 2424 </li>
                        <li>
                            <ul className="rrss" >
                                <li> <a href="https://www.facebook.com/dtop" target="_blank" rel="noreferrer"> <Facebook className="icon-footer" /> </a></li >
                                <li> <a href="https://twitter.com/DTOP" target="_blank" rel="noreferrer"> <Twitter className="icon-footer" /> </a></li >
                                <li> <a href="https://www.youtube.com/c/dtopoficial" target="_blank" rel="noreferrer"> <YouTube className="icon-footer" /> </a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="col-auto">
                    <ul className="list">
                        {/*<li><a href="#"> Términos y condiciones para el uso del Portal Cibernético </a></li>*/}
                        {/*<li> Solicitud CEE - SA - 2020 - 5387 </li>*/}
                        <img className="footer__logo" src={dtopLogoFooter} alt="logo footer" />
                    </ul>
                </div>
            </div>
        );
    }
}

export default Footer;