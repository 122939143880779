import axios from "axios";

class PaymentService {

  doPayment = (paymentData) => {
    const data = {
      'finesId': paymentData.finesId, 
      'email': paymentData.email,
      'amount': paymentData.amount,
      'cardHolder': paymentData.cardHolder,
      'cardNumber': paymentData.cardNumber,
      'ccv': paymentData.cvv,
      'cardExpirationDate': paymentData.cardExpirationDate,
      'billAddress': paymentData.billAddress,
      'cityBillAddress': paymentData.cityBillAddress,
      'stateBillAddress': paymentData.stateBillAddress,
      'zipcodeBillAddress': paymentData.zipcodeBillAddress,
      'ownerId': paymentData.ownerId
    };
    return axios.post(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/fines`,
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem('token')}`
        }
      }).then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
  }
}

export default new PaymentService();
