import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WifiOffIcon from '@material-ui/icons/WifiOff';

import './NoInternetModal.css';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    }
}));

const NoInternetModal = (props) => {

    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalState}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.modalState}>
                <div className='noInternet-modal'>
                    <Grid className='noInternet-container'>
                        <h2>Oops! Compruebe su conexión a internet.</h2>
                        <List>
                            <ListItem>
                                <WifiOffIcon className='noInternet-icon' />
                                <ListItemText primary="Por favor," secondary='Vuelva a intentarlo nuevamente.' />
                            </ListItem>
                        </List>
                    </Grid>
                    <HighlightOffIcon className='btn-close' onClick={() => props.parentCallback()} />
                </div>
            </Fade>
        </Modal>
    );
}

export default NoInternetModal
