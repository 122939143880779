import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { ArrowBack } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import './modal-card.css';

import underConstruction from '../../../img/under-construction.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '50px',
  },
  paper: {
    boxShadow: theme.shadows[5],
    backgroundColor: '#FFF',
    padding: theme.spacing(2, 4, 3),
    width: '85%',
    maxWidth: '780px',
  },
}));

const TyCModal = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.modalState}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalState}>
          <div className={classes.paper}>

            <div className="modal-body__terms">
              <h2>Términos y Condiciones</h2>

              <img className="modal__img" src={underConstruction} alt="under construction" />

              <p className="mt-3">
                <Button
                  variant="contained"
                  className="btn-simple"
                  startIcon={<ArrowBack />}
                  onClick={() => props.parentCallback(false)}
                >
                  Regresar
                </Button>
              </p>

            </div>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default TyCModal;