import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AeTooltip from '../AutoExpreso/Tooltip';


const FinesTotals = (props) => {
  const data = props.data;
  return (
    <div>

      <List className="list-sidebar">
        <ListSubheader component="div" id="">
          Resumen de Deuda
        </ListSubheader>

        <ListItem>
          <ListItemText primary={`Autoexpreso (${data.qtty.autoexpreso})`} secondary={`$${(data.amounts.autoexpreso).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
          <AeTooltip></AeTooltip>          
        </ListItem>

        <ListItem>
          <ListItemText primary={`Licencia (${data.qtty.licencia})`} secondary={`$${(data.amounts.licencia).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
        </ListItem>

        <ListItem>
          <ListItemText primary={`Vehiculo (${data.qtty.vehiculo})`} secondary={`$${(data.amounts.vehiculo).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
        </ListItem>

        <ListItem>
          <ListItemText primary={`Total (${data.qtty.total})`} secondary={`$${(data.amounts.total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
          {/*<ListItemText primary="Total (40)" secondary="$12,000.00" />*/}
        </ListItem>
      </List>

    </div>
  );
}

export default FinesTotals;