import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '20px',
  },
  paper: {
    boxShadow: theme.shadows[5],
    backgroundColor: '#FFF',
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: '780px',
  },
}));

const FineDetails = (props) => {
  const classes = useStyles();

  const fine = props.fineDetail;

  const compareDates = (value, type, amount) => {
    const endDate = new Date("2017-07-01 12:00:00").getTime();
    const startDate = new Date("2004-01-01 12:00:00").getTime();
    const accessDate = new Date(`${value} 12:00:00`).getTime();
    if (type !== 'AUTOEXPRESO') {
      if (accessDate >= endDate) {
        return (
          <Grid item xs className="text-conditions">Según Enmienda <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2/0024-2017.pdf" target="_blank" rel="noreferrer">
            Ley Num 24 del 29 de Abril 2017</a>
          </Grid>
        );
      } else if (endDate > accessDate && accessDate >= startDate) {
        return (
          <Grid item xs className="text-conditions">Según Enmienda <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2/0056-2005.pdf" target="_blank" rel="noreferrer">
            Ley Num 56 del 19 de Agosto 2005</a>
          </Grid>
        );
      } else {
        return (
          <Grid item xs className="text-conditions">Según Enmienda <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2/0056-2005.pdf" target="_blank" rel="noreferrer">
            Ley Num 56 del 19 de Agosto 2005</a>
          </Grid>
        );
      }
    } else {
      if(amount > 0) {
        return (
          <Grid item xs className="text-conditions">Según Enmienda <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2/0024-2017.pdf" target="_blank" rel="noreferrer">
            Ley Num 24 del 29 de Abril 2017</a>
          </Grid>
        );
      } else {
        return (
          <Grid item xs className="text-conditions">Según Enmienda <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2018/0220-2018.pdf" target="_blank" rel="noreferrer">
            Ley Num 220 del 17 de Septiembre 2018</a>
          </Grid>
        );
      }
    }
   
  }

  function createData(name, unidad, monto) {
    return { name, unidad, monto };
  }

  const rows = [];
  let totalAmount = 0;

  if (fine && fine.surchargeDetails) {
    let total = 0;
    rows.push(createData('Valor de la Multa', '', fine.amount));
    if(fine.type !== 'AUTOEXPRESO') {
      rows.push(createData('Total Multa', '', fine.amount));
    } else {
      rows.push(createData('Valor Peaje', '', fine.tollAmount));
      if (fine.surchargeDetails.length > 0) {
        rows.push(createData('SubTotal', '', fine.amount + fine.tollAmount));
      }
    }
    
    fine.surchargeDetails.forEach((s) => {
      total += s.value;
      rows.push(createData(s.description, s.unity, s.value));
    });
    if (total) {
      rows.push(createData('Total Costos Adicionales', '', total));
    }
    if (fine.type !== 'AUTOEXPRESO') {
      totalAmount = total + fine.amount;
    } else {
      totalAmount = total + fine.amount + fine.tollAmount;
    }
    
  }

  const getSpanishType = (value) => {
    let type = '';
    if (value === 'LICENSE') {
      type = 'LICENCIA';
    } else if (value === 'VEHICLE') {
      type = 'VEHICULO';
    } else {
      type = 'AUTOEXPRESO';
    }
    return type;
  }
  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.modalState}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onEscapeKeyDown={() => props.parentCallback(false)}
        disableRestoreFocus={true}
      >
        <Fade in={props.modalState}>
          <div className={classes.paper}>
            {fine ? (
              <div className="modal-body__fineDetails">
                <h2>Detalle de Multa</h2>
                <div className="modal-scroll">
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                      <List className="list-inline__value-witdh">
                        <ListItem>
                          <ListItemText primary="No. Boleto" secondary={`${fine.ticketNumber}`} />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="Fecha de la multa" secondary={`${new Date(`${fine.date} 12:00:00`).toLocaleDateString("en-US")}`} />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="Ciudad" secondary={`${fine.city}`} />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="Tipo" secondary={`${getSpanishType(fine.type)}`} />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="Tablilla" secondary={`${(fine.plateNumber != null) ? fine.plateNumber : ''}`} />
                        </ListItem>

                        <ListItem>
                          <ListItemText primary="Descripción" secondary={`${fine.lawDescription}`} />
                        </ListItem>

                      </List>
                    </Grid>
                  </Grid>

                  <div className="simple-table simple-table__responsive">
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>Descripción Costos Aplicados</strong></TableCell>
                            <TableCell width="90px"><strong>Unidad</strong></TableCell>
                            <TableCell align="right" width="120px"><strong>Cantidad</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow key={row.name} className={row.name === 'Total Multa' || row.name === 'SubTotal' || row.name === 'Total Costos Adicionales' ? 'row-gray' : ''}>
                              <TableCell component="th" scope="row">
                                {row.name === 'Total Multa' || row.name === 'SubTotal' || row.name === 'Total Costos Adicionales' ? <strong>{row.name}</strong> : row.name}
                              </TableCell>
                              <TableCell>{row.unidad}</TableCell>
                              <TableCell align="right">{
                                row.name === 'Total Multa' || row.name === 'SubTotal' || row.name === 'Total Costos Adicionales' ?
                                  <strong>${row.monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong> :
                                  `$${row.monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
                              }</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <hr />

                  <div className="simple-table">
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>
                              <p className="detail-result">Total a Pagar
                                <br />
                                <span className="text-xsm">(Valor Multa + Costos Adicionales)</span>
                              </p>
                            </strong></TableCell>
                            <TableCell width="90px"><strong></strong></TableCell>
                            <TableCell align="right" width="120px"><strong className="detail-result">${totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong></TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </div>
                </div>

                <Grid container spacing={3} alignItems="center" className="space-between mt-3">
                  <div>
                    {
                      fine.type === 'AUTOEXPRESO' ? <Grid item xs className="text-conditions mb-1">Visita <a href="https://www.autoexpreso.com/login" target="_blank" rel="noreferrer">https://www.autoexpreso.com/login</a> para
                      proceder con el pago
                    </Grid> : ''
                    }
                    <Grid item xs className="text-conditions mb-1">Calculado de acuerdo a <a href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/Veh%C3%ADculos%20y%20Tr%C3%A1nsito/22-2000/22-2000.pdf" target="_blank" rel="noreferrer">
                      Ley Num 22 del 7 de Enero 2000
                    </a>
                    </Grid>
                    {
                      compareDates(fine.date, fine.type, fine.amount)
                    }
                  </div>
                  <Grid item xs={12} sm={4} className="align-right">
                    <Button className="special-padding" onClick={() => props.parentCallback(false)} type="submit" variant="contained" color="primary">
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : ''}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default FineDetails;