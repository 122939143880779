import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Link } from 'react-router-dom';

const SelectionSummary = (props) => {

  function handleSubmit() {
    props.finesCallback(false, 'payment');
  }

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={3}>
          <div className="txt-fines ptblr">
            <strong>Cantidad a pagar</strong>
            <p>por ({props.amounts.selectedCounter}) multa{(props.amounts.selectedCounter === 1) ? '' : 's'} seleccionada{(props.amounts.selectedCounter === 1) ? '' : 's'}</p>
          </div>
        </Grid>

        <Grid item xs>
          <List className="list-inline  align-right">
            <ListItem>
              <ListItemText primary={"$" + props.amounts.finesAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} secondary="Total Multa" />
            </ListItem>

            <ListItem className="recharge">
              <ListItemText primary={"+$" + props.amounts.surchargeAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} secondary="Total Recargos" />
            </ListItem>

            <ListItem className="discount">
              <ListItemText primary={"-$" + props.amounts.discountAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} secondary="Total Descuentos" />
            </ListItem>

            <ListItem className="total align-right">
              ${props.amounts.totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Link to="/pago">
            <Button type="button" onClick={handleSubmit} variant="contained" color="primary" disabled={!props.amounts.totalAmount}>
              Pagar
            </Button>
          </Link>
        </Grid>
      </Grid>

    </div>
  );
}

export default SelectionSummary;