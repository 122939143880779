import { CARD_TYPE } from './Constants/Constants';

const hideRecaptcha = () => {
    const grecaptcha = document.querySelector('.grecaptcha-badge');
    grecaptcha.style.display = 'none';
}

const showRecaptcha = () => {
    const grecaptcha = document.querySelector('.grecaptcha-badge');
    grecaptcha.style.display = 'block';
}

const removeDuplicatebyId = (arr) => {
    const temporaryFines = arr.map(fine => {
      return [fine.id, fine]
    });

    return [...new Map(temporaryFines).values()];
}

// Only numbers
const handleOnKeyPressOnlyNumbers = (event) => {
    const value = event.key;
    if (!(/^[0-9]*$/.test(value))) {
      event.preventDefault();
    }
};

// Only numbers and letters
const handleOnKeyPressOnlyNumbersLetters = (event) => {
    const value = event.key;
    if (!(/^[a-zA-Z0-9 ]+$/.test(value))) {
      event.preventDefault();
    }
};

// Only letters
const handleOnKeyPressOnlyLetters = (event) => {
    const value = event.key;
    if (!(/^[a-zA-Z ]+$/.test(value))) {
      event.preventDefault();
    }
};

// Only certain special letters
const handleOnKeyPressOnlyCertainSpecialLetters = (event) => {
  const value = event.key;
  if (!(/^[a-zA-Z0-9\u00f1\u00d1 Üü,.;:#-]+$/.test(value))) {
    event.preventDefault();
  }
};

// Detect card type
const detectCreditCardType = (value) => {
  const cardNumber = value;
  const firstNumber = cardNumber.slice(0, 1);
  const secondNumber = cardNumber.charAt(1, 1);
  const creditCardlength = cardNumber.length;

  if (firstNumber === '4' && (creditCardlength === 13 || creditCardlength === 16)) {
    return CARD_TYPE.VISA;
  } else if (firstNumber === '5' && creditCardlength === 16) {
    return CARD_TYPE.MASTERCARD;
  } else if (firstNumber === '3' && (secondNumber === '4' || secondNumber === '7') && creditCardlength === 15) {
    return CARD_TYPE.AMEX;
  } else if (firstNumber === '6' && creditCardlength === 16) {
    return CARD_TYPE.DISCOVER;
  } else if (firstNumber === '3' && (secondNumber === '0' || secondNumber === '6' || secondNumber === '8') && creditCardlength === 14) {
    return CARD_TYPE.DINERS;
  } else {
    return CARD_TYPE.NONE;
  }
}

export { hideRecaptcha, showRecaptcha, removeDuplicatebyId, handleOnKeyPressOnlyNumbers, handleOnKeyPressOnlyNumbersLetters, 
         handleOnKeyPressOnlyLetters, handleOnKeyPressOnlyCertainSpecialLetters, detectCreditCardType };