import React from 'react';
import dtopLogo from '../../img/logo-dtop.png';
import { Person, ExitToApp, ArrowBack } from '@material-ui/icons';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

import './header.css';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.props.setStep('finesPanel');
  }

  render() {
    return (

      <div className="header-container">
        <div className="main-header">
          <div className="col-flex">
            <img className="main-header__logo" src={dtopLogo} alt="logo1" /> <span className="main-header__title">Pago Fácil de boletos DTOP</span>
          </div>

          {this.props.userData ?
            <div className="col-auto mr-1">
              <div className="header__module">
                <div className="header__module--txt pr-1">
                  <p><strong>{this.props.userData.fullName}</strong></p>
                  <p>Lic. {this.props.userData.license}</p>
                </div>
                <div className="header__module--icon">
                  <Person />
                </div>
              </div>
            </div>
            : ''}


          {this.props.userData ?
            <div className="col-auto">
              <Link to="/">
                <Button
                  variant="contained"
                  className="btn-secondary"
                  startIcon={<ExitToApp />}
                  onClick={this.props.handleLogout}
                >
                  Salir
                </Button>
              </Link>
            </div>
            : ''}
        </div>

        <Breadcrumbs className="main-breadcrumb" aria-label="breadcrumb">
          {this.props.step === 'payment' ? (
            <Link to="/seleccion">
              <label className="breadcrumb-link" onClick={this.handleClick}>
                <ArrowBack fontSize="small" /> Selección de multas
              </label>
            </Link>
          ) : ''}
          {this.props.step === 'payment' ? (
            <label className="breadcrumb-link" >
              Datos de pago
            </label>
          )
            : ''}
        </Breadcrumbs>
      </div>
    );
  }
}

export default Header;
