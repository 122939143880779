import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Warning, LocalOffer } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const ExpandableFineRow = ({ data, callbackShowModal }) => {
    return (
        <div className="expandable-card">
            <Grid container spacing={1}>
                <Grid item xs>
                    <span className="text-sm">- Ciudad: <strong>{data.city}</strong></span>
                </Grid>
                <Grid item xs>
                    <Button
                        onClick={() => callbackShowModal(true, data.id)}
                        variant="contained"
                        className="btn-simple special-hover"
                        size="small"
                    >
                        +info
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <span className="text-sm">- Tablilla: <strong>{data.plateNumber || 'No tiene.'}</strong></span>
                </Grid>
                <Grid item xs={12}>
                    {data.surchargeDetails.some(surchargeDeatail => surchargeDeatail.value > 0) ? <Warning className="icon-table" style={{ color: '#CF4646' }} /> : ''}
                    {data.surchargeDetails.some(surchargeDeatail => surchargeDeatail.value < 0) ? <LocalOffer className="icon-table" style={{ color: '#5C9F3D' }} /> : ''}
                    {data.surchargeDetails.length === 0 ? <span className="text-sm litle-gray"><strong><i>No hay interes o descuento.</i></strong></span> : ''}
                </Grid>
            </Grid>
        </div>
    )
}

export default ExpandableFineRow;