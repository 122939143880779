import axios from "axios";
import moment from 'moment';

class FinesService {
    
  getOwnerFines = (licOrPlate, ssn, passport, birthdate, token) => {
    const birthdateFormatted = moment(birthdate).format("YYYY-MM-DD");
      return axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/owner`,
        {params: {'licenseOrPlate': licOrPlate, 'ssnLast4': ssn, 'passLast4': passport,'dateOfBirth': birthdateFormatted, 'tokenRecaptcha': token}},
      ).then(response => {
        sessionStorage.setItem("userId", response.data.data.id);

        return response.data;
      }).catch(err => {
        console.error(err);
        throw err;
      });
  }

  getFines = (page) => {
    const userId = sessionStorage.getItem("userId");
    return axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/owner/${userId}`, 
      {params: {'pageNumber': page}}
      ).then(response => {
        return response.data;
      }).catch(err => {
        console.error(err);
        throw err;
      });
  }

}

export default new FinesService();
