import React from 'react';
import './FinesPanel.css';
import FinesList from './FinesList/FinesList';
import FinesFilter from './FinesFilter/FinesFilter';
import FinesTotals from './FinesTotals/FinesTotals';
import SelectionSummary from './SelectionSummary/SelectionSummary';
import Grid from '@material-ui/core/Grid';


const FinesPanel = (props) => {
  const finesTotals = props.totals;

  return (
    <div className='finesPanel-container'>
      <div className='finesPanel-stepper'>
        {props.currentStepper}
      </div>
      <div className="primary-title mls-1">Consulta de deuda multas</div>

      <Grid className="content-wrapper pb-content--pay sidebar-responsive only-table-no-sidebar" container spacing={3}>
        <Grid item xs={12} sm={2}>
          <FinesTotals data={finesTotals} className="bg" />
          <FinesFilter filterCallback={props.filterCallback} checkedFilters={props.checkedFilters} />
        </Grid>

        <Grid item xs className="table-content p-0 pl-1">
          <FinesList totals={finesTotals} fines={props.fines} finesCallback={props.finesCallback} pageSelectionUpdate={props.pageSelectionUpdate}
            selectionModelArray={props.selectionModelArray} />
        </Grid>
      </Grid>

      <Grid className="content-summary">
        <SelectionSummary amounts={props.finalSelection} finesCallback={props.finesCallback} />
      </Grid>

    </div>
  );
}

export default FinesPanel;
