import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import serverError from '../../img/server-error.jpg';
import './ErrorModal.css';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    }
}));

const ErrorModal = (props) => {

    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalState}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.modalState}>
                <div className='error-modal'>
                    <Grid className='error-container'>
                        <h2>Oops! Ocurrió un error inesperado.</h2>
                        <List>
                            <ListItem>
                                <ListItemText primary="Por favor," secondary='Vuelva a intentarlo dentro de un momento.' />
                            </ListItem>
                        </List>
                        <img height='250' width='250' src={serverError} alt="failed-server" />
                    </Grid>
                    <HighlightOffIcon className='btn-close' onClick={() => props.parentCallback()} />
                </div>
            </Fade>
        </Modal>
    );
}

export default ErrorModal;