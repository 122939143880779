import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FailPayment from '../../../img/icon-alert.png';
import logoColecturia from '../../../img/logo-colecturia.png';
import AutoexpresoNotifier from '../../FinesPanel/AutoExpreso/Notification';

import Button from '@material-ui/core/Button';
import './modal-card.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '50px',
  },
  paper: {
    boxShadow: theme.shadows[5],
    backgroundColor: '#FFE3E3',
    padding: theme.spacing(2, 4, 3),
    width: '85%',
    maxWidth: '500px',
  },
}));

const PaymentModal = (props) => {
  const checkAutoexpreso = function () {
    const totals = props.totals.qtty;
    return totals.autoexpreso > 0;
  };

  const classes = useStyles();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.modalState}
        onClose={props.parentCallback}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalState}>
          <div className={classes.paper}>

            <div className="modal-body__fail">
              <div><img src={FailPayment} alt="fail-transaction" /></div>
              <h2>Transacción Fallida</h2>
              <div>{ props.description ? props.description : 'El pago fue rechazado.' }</div>
              {
                props.colecturia ? <>
                  <div className='mt-1 modal-decription'>{props.description}</div><div className='mt-1'>Si tiene dudas sobre el cobro de las multas puede comunicarse con
                    Colecturía
                    Digital al (787) 291-9292 o por correo
                    electrónico a <a
                      href="mailto:servicioalcliente@colecturiadigital.com"
                      rel="noreferrer"
                      target="_blank"
                      className='modal-link'>servicioalcliente@colecturiadigital.com</a></div></> : ''
              }
              { checkAutoexpreso() ? <AutoexpresoNotifier /> : null }
              <p className="mt-2">
                <Button className="special-padding" onClick={() => props.clear ? props.gotoHome() : props.parentCallback(false)} type="submit" variant="contained" color="primary">
                  Continuar
                </Button>
              </p>
              {
                props.colecturia ?
                  <img className="paymentModal-colecturia" src={logoColecturia} alt="logo colecturia" /> : ''
              }
            </div>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PaymentModal;