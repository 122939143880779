import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  document.getElementById('circular-loading').classList.remove('hide-loading');
  document.getElementById('circular-loading').classList.add('show-loading');
  return config;
}, function (error) {
  document.getElementById('circular-loading').classList.remove('hide-loading');
  document.getElementById('circular-loading').classList.add('show-loading');
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  document.getElementById('circular-loading').classList.remove('show-loading');
  document.getElementById('circular-loading').classList.add('hide-loading');
  return response;
}, function (error) {
  document.getElementById('circular-loading').classList.remove('show-loading');
  document.getElementById('circular-loading').classList.add('hide-loading');
  return Promise.reject(error);
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
