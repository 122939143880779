import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Warning, LocalOffer } from '@material-ui/icons';
import FineDetails from '../FineDetails/FineDetails';
import ExpandableFineRow from './ExpandableFineRow';
import Checkbox from '@material-ui/core/Checkbox';
import AutoexpresoNotifier from '../AutoExpreso/Notification';

const FinesList = (props) => {

  const userFines = props.fines.items;

  const [fineDetail, setFineDetail] = useState({});
  const [modalState, setModalState] = useState(false);
  const showModal = (modalOpen, fineId) => {
    setFineDetail(userFines.find(fine => fine.id === fineId));
    setModalState(modalOpen);
  };

  const compareValidDate = (type, dateValue) => {
    
    if (type !== 'AUTOEXPRESO') {
      return true;
    } else {
      const limitDate = new Date("2021-07-01 12:00:00").getTime();
      const currentDate = new Date(`${dateValue} 12:00:00`).getTime();
      return currentDate >= limitDate ? true : false;
    }
  }

  const columns = [
    {
      name: 'No.Boleto',
      selector: row => row.ticketNumber,
      sortable: true,
      width: '130px'
    },
    {
      name: 'Fecha',
      selector: row => row.date,
      sortable: true,
      cell: row => {
        const today = new Date(row.date);
        today.setDate(today.getDate() + 1);
        return today.toLocaleDateString("en-US");
      }
    },
    {
      name: 'Ciudad',
      selector: row => row.city,
      sortable: true,
      hide: 'sm',
      width: '140px'
    },
    {
      name: 'Tablilla',
      selector: row => row.plateNumber,
      sortable: true,
      hide: 'sm'
    },
    {
      name: 'Total',
      selector: row => row.amount,
      sortable: true,
      cell: row => <strong>${(row.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong>,
    },
    {
      name: '',
      selector: row => row.surchargeDetails,
      hide: 'sm',
      width: '60px',
      cell: row =>
        <strong>
          {row.surchargeDetails.some(surchargeDeatail => surchargeDeatail.value > 0) && compareValidDate(row.type, row.date) ? <Warning className="icon-table" style={{ color: '#CF4646' }} /> : ''}
          {row.surchargeDetails.some(surchargeDeatail => surchargeDeatail.value < 0) && compareValidDate(row.type, row.date) ? <LocalOffer className="icon-table" style={{ color: '#5C9F3D' }} /> : ''}
        </strong>
    },
    {
      name: '',
      selector: row => row.id,
      hide: 'sm',
      width: '100px',
      cell: row =>
        <strong>
          <Button
            onClick={() => showModal(true, row.id)}
            variant="contained"
            className="btn-simple special-hover"
            size="small"
          >
            +info
          </Button>
        </strong>
    },
  ];

  const checkAutoexpreso = function () {
    const totals = props.totals.qtty;
    return totals.autoexpreso > 0 && totals.licencia === 0 && totals.vehiculo === 0;
  };
  

  return (
    <div>
      <div className="mod-search-table">
        <div className="col-auto">Seleccione las multas a pagar.</div>
      </div>

      { checkAutoexpreso() ? <AutoexpresoNotifier img bgColor="white" /> : null }

      <DataTable
        data={userFines}
        columns={columns}
        keyField='id'
        pagination
        paginationPerPage={10}
        selectableRows
        selectableRowsComponent={Checkbox}
        Clicked
        onSelectedRowsChange={props.pageSelectionUpdate}
        selectableRowSelected={(row) => row.selected}
        expandableRows
        expandableRowsComponent={(row) => <ExpandableFineRow data={row.data} callbackShowModal={showModal} />}
        highlightOnHover
        selectableRowDisabled={(row) => row.type === 'AUTOEXPRESO'}
        noDataComponent={(<p>No hay registros para mostrar</p>)}
      />

      <FineDetails modalState={modalState} fineDetail={fineDetail} parentCallback={showModal} />

      <ul className="table-leyend">
        <li><Warning style={{ color: '#CF4646' }} />Con intereses</li>
        <li><LocalOffer style={{ color: '#5C9F3D' }} />Con descuento</li>
      </ul>
    </div>
  );
}

export default FinesList;