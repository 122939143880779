import React from "react";
import Grid from "@material-ui/core/Grid";
import warningIcon from "../../../img/warning.png";
import Card from "@material-ui/core/Card";

let cardStyle = {
  transitionDuration: "0.3s",
  maxHeight: "100px",
  backgroundColor: "transparent",
  marginTop: '.5rem'
};

let imgStyle = {
  transitionDuration: "0.3s",
  width: "60px",
  height: "60px",
};

const Notifier = (props) => {
  return (
    <Card style={props.bgColor? {backgroundColor: props.bgColor, marginTop: '.5rem'} : cardStyle} elevation={0}>
      <Grid container alignItems="center" justify="center">
        {props.img ? (
          <Grid item xs={2} alignItems="center" justify="center" container>
            <img src={warningIcon} style={imgStyle} alt="Warning icon"></img>
          </Grid>
        ) : null}
        <Grid item xs={10}>
          <div>
            Usted tiene multas de Autoexpreso pendientes por pagar. Por favor diríjase a
            &nbsp;
            <a href="https://www.autoexpreso.com/" style={{color: "blue"}}>www.autoexpreso.com</a>&nbsp;
            para ponerse al día con sus obligaciones
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Notifier;
