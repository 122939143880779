import React, { useState } from 'react';

import dtopSteps from '../../img/dtop-steps-v2.png';
import TyCModal from './TyCModal/TyCModal';
import ErrorModal from '../../shared/ErrorModal/ErrorModal';
import NoInternetModal from '../../shared/NoInternetModal/NoInternetModal';
import FinesService from "../../services/FinesService";

import { TextField, Button, FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import "moment/locale/es";

import { useFormik, Field, FormikProvider } from 'formik';
import * as yup from 'yup';

import * as Utils from '../../shared/Utils';
import { HTTP_STATUS } from '../../shared/Constants/Constants';
import './UserForm.css';

const localeMap = {
  en: enLocale,
  es: esLocale
};

const UserForm = (props) => {

  const [ssnLast4, setSsnLast4] = React.useState(true);
  const toggleLast4 = () => {
    setSsnLast4(!ssnLast4);
  };

  const [modalState, setModalState] = React.useState(false);
  const showModal = (modalOpen) => {
    setModalState(modalOpen);
  };

  const [errorModalState, setErrorModalState] = React.useState(false);
  const showErrorModal = (modalOpen) => {
    setErrorModalState(modalOpen);
  };

  const [noInternetModalState, setNoInternetModalState] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const gotoHome = () => {
    showErrorModal(false);
    props.clearSesion();
  }

  const hideInternetModal = () => {
    setNoInternetModalState(false);
  }

  const validationSchema = yup.object({
    dummy: yup.string(),
    licOrPlate: yup
      .string('Ingrese su Licencia')
      .required('Licencia es requerido'),
    ssn: yup
      .string('Ingrese su SSN')
      .when('dummy', {
        is: (value) =>
          ssnLast4 === true,
        then: yup.string()
          .required('SSN es requerido')
          .matches(/^\d{4}$/, 'SSN solo debe ser numeros positivos y tener 4 digitos')
      }),
    passport: yup
      .string('Ingrese su Pasaporte')
      .when('dummy', {
        is: (value) =>
          ssnLast4 === false,
        then: yup.string()
          .required('Pasaporte es requerido')
          .matches(/^\d{4}$/, 'Pasaporte solo debe ser numeros positivos y tener 4 digitos')
      }),
    birthdate: yup
      .string('Ingrese su Fecha Nacimiento')
      .required('Fecha Nacimiento es requerido'),
    checked: yup
      .boolean('Aceptar los Terminos y Condiciones')
      .oneOf([true], 'Terminos y Condiciones es requerido')
  });

  const formik = useFormik({
    initialValues: {
      licOrPlate: '',
      ssn: '',
      passport: '',
      birthdate: null,
      checked: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(`${process.env.REACT_APP_RECAPTCHA}`, { action: 'submit' }).then(function (token) {
          if (token) {
            const data = ssnLast4 ? { ...values, passport: '' } : { ...values, ssn: '' };
            setLoading(true);
            return FinesService.getOwnerFines(data.licOrPlate.trim(), data.ssn.trim(), data.passport.trim(), formatBirthday(values.birthdate), token)
              .then((res) => {
                setLoading(false);
                props.ownerCallback({ fullName: res.data.fullName, license: res.data.licenseNumber }, res.data.fines);
                localStorage.setItem('token', res.token);
                Utils.hideRecaptcha();
                props.history.replace('/seleccion');
              }).catch((err) => {
                setLoading(false);

                if (err.toJSON().message === HTTP_STATUS.MSG_OFFLINE) {
                  setNoInternetModalState(true);
                  return;
                }

                if (err.response) {
                  if (err.response.status === HTTP_STATUS.CODE_400) {
                    setErrorMessage('No se acepto la solicitud de pago debido a que no cumplió con todas las validaciones.');
                  } else if (err.response.status === HTTP_STATUS.CODE_403) {
                    setErrorMessage('No pasó la validación de recaptcha, vuelva a intentarlo en un rato.');
                  } else if (err.response.status === HTTP_STATUS.CODE_404) {
                    setErrorMessage('No fue posible validar sus datos según sometidos. Verifique los mismos e intente nuevamente.');
                  } else {
                    showErrorModal(true);
                  }
                }
              });
          }
        });
      });
    },
  });

  const formatBirthday = (value) => moment(new Date(value)).format('YYYY-MM-DD');

  return (
    <div className="userForm-container userForm-height">
      <Grid className="user-content-wrapper" container spacing={5}>

        <Grid item xs>
          <div className='userForm-stepper'>
            {props.currentStepper}
          </div>
          <div className="primary-title mt-2">
            <span className="color-green">Pago Fácil</span> de Boletos DTOP
          </div>
          <div className="description">Pague sus boletos en solo tres pasos.</div>

          <form className="mt-3" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="licOrPlate"
                  label="Licencia o Tablilla"
                  variant="outlined"
                  fullWidth
                  value={formik.values.licOrPlate}
                  onChange={formik.handleChange}
                  error={formik.touched.licOrPlate && Boolean(formik.errors.licOrPlate)}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {ssnLast4 ?
                  <TextField
                    name="ssn"
                    label="SSN"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                    onKeyPress={Utils.handleOnKeyPressOnlyNumbers}
                    error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                  />
                  :
                  <TextField
                    name="passport"
                    label="Pasaporte"
                    variant="outlined"
                    type="passport"
                    fullWidth
                    value={formik.values.passport}
                    onChange={formik.handleChange}
                    error={formik.touched.passport && Boolean(formik.errors.passport)}
                  />
                }
                <p className="m-0"><small>Últimos 4 dígitos</small></p>
                <p className="text-conditions">
                  <strong>
                    <label className="link-green" name="last4" onClick={toggleLast4}>{ssnLast4 ?
                      <span>¿Desea validar con Pasaporte?</span> :
                      <span>¿Desea validar con SSN?</span>}
                    </label>
                    {ssnLast4 ?
                      <Tooltip leaveTouchDelay={1000} enterTouchDelay={50} title="Puede usar su número de Pasaporte siempre y cuando este registrado en el sistema" className="userForm-tooltip" arrow><HelpIcon /></Tooltip> :
                      <Tooltip leaveTouchDelay={1000} enterTouchDelay={50} title="Puede usar su Social Security Number(SSN) siempre y cuando este registrado en el sistema" className="userForm-tooltip" arrow><HelpIcon /></Tooltip>
                    }
                  </strong>
                </p>

              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['en']}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Fecha Nacimiento"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    fullWidth
                    name="birthdate"
                    value={formik.values.birthdate}
                    onChange={val => formik.setFieldValue('birthdate', val)}
                    error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <FormikProvider value={formik}>
                  <Field name="checked"
                    component={({
                      field,
                      form: { touched, errors },
                      ...props
                    }) => (
                      <div className={'text-conditions center mt-3 mb-3 ' + (errors.checked && touched.checked ? 'color-error' : '')}>
                        <Checkbox className="mt-0" checked={field.value} {...field} {...props} />
                        <p>He leído y estoy de acuerdo con los <strong><label className="link-green" name="tyc" onClick={() => showModal(true)}>Términos y Condiciones</label></strong> de este portal</p>
                      </div>
                    )} />
                </FormikProvider>

                <Button className="special-padding" type="submit" variant="contained" color="primary" disabled={!formik.isValid || (formik.isValid && loading)}>
                  Buscar Multas
                </Button>
              </Grid>

              {!!errorMessage && (
                <FormHelperText error id="error" className="text-error">
                  {errorMessage}
                </FormHelperText>
              )}

            </Grid>
          </form>
        </Grid>

        <Grid item xs={12} sm={5} className="col-image">
          <img src={dtopSteps} alt="steps-dtop" />
        </Grid>
      </Grid>
      <TyCModal modalState={modalState} parentCallback={showModal} />
      <ErrorModal modalState={errorModalState} parentCallback={gotoHome} />
      <NoInternetModal modalState={noInternetModalState} parentCallback={hideInternetModal} />
    </div>
  );
}


export default UserForm;
