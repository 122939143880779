import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import './CustomStepper.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Página Inicio', 'Listado de Multas', 'Pago de Multas'];
}

const CustomStepper = (props) => {
  const classes = useStyles();
  const currentStep = props.currentStep || 0;
  const currentComplete = props.currentComplete || { 0: true };

  const [activeStep] = React.useState(currentStep);
  const [completed] = React.useState(currentComplete);
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} className='customStepper-background'>
        {steps.map((label, index) => (
          <Step key={label} id={`customStepper-node-${index}`}>
            <StepLabel completed={completed[index]}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default CustomStepper;
