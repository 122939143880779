import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import UserForm from './components/UserForm/UserForm';
import FinesPanel from './components/FinesPanel/FinesPanel';
import PaymentForm from './components/PaymentForm/PaymentForm';
import PaymentConfirmation from './components/PaymentForm/PaymentConfirmation';
import CustomStepper from './shared/CustomStepper/CustomStepper';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Utils from './shared/Utils';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize(`${process.env.REACT_APP_GA}`);

function App() {

  const initialSummary = {
    qtty: { autoexpreso: 0, licencia: 0, vehiculo: 0, total: 0 },
    amounts: { autoexpreso: 0, licencia: 0, vehiculo: 0, total: 0 }
  };
  const initialFinalSelection = { finesAmount: 0.00, totalAmount: 0.00, discountAmount: 0.00, surchargeAmount: 0.00, selectedCounter: 0, finesId: [] };


  const [user, setUser] = useState();
  const [fines, setFines] = useState();
  const [finesFiltered, setFinesFiltered] = useState();
  const [transaction, setTransaction] = useState();
  const [finalSelection, setFinalSelection] = useState(initialFinalSelection);
  const [nextStep, setNextStep] = useState();

  const [summary, setSummary] = useState(initialSummary);

  const [selectionModelArray, setSelectionModelArray] = useState([]);
  const [checkedFilters, setCheckedFilters] = useState([]);

  const updateFines = (finesInfo) => {
    calcSummary(finesInfo.items);
    setFines(finesInfo);
    finesInfo.items = finesInfo.items.map(item => item.type !== 'AUTOEXPRESO' ? { ...item, selected: true } : { ...item, selected: false });
    finesInfo.items = finesInfo.items.filter(item => item.type !== 'AUTOEXPRESO');
    setFinesFiltered(finesInfo);
  }

  const clearSesion = () => {
    setUser(null);
    setFines(null);
    setFinesFiltered(null);
    setFinalSelection(initialFinalSelection);
    setSummary(initialSummary);
    setNextStep('');
    localStorage.removeItem('token');
    setCheckedFilters([]);
    setSelectionModelArray([]);
    Utils.showRecaptcha();
  };

  const calcSummary = (fines) => {
    fines.forEach((fine) => {
      if (fine.type === 'LICENSE') {
        summary.qtty.licencia++;
        summary.amounts.licencia += fine.amount + fine.surchargeAmount;
      } else if (fine.type === 'VEHICLE') {
        summary.qtty.vehiculo++;
        summary.amounts.vehiculo += fine.amount + fine.surchargeAmount;
      }
      else if (fine.type === 'AUTOEXPRESO') {
        summary.qtty.autoexpreso++;
        summary.amounts.autoexpreso += fine.amount + fine.surchargeAmount;
      }
      summary.qtty.total = summary.qtty.licencia + summary.qtty.vehiculo + summary.qtty.autoexpreso;
      summary.amounts.total = summary.amounts.licencia + summary.amounts.vehiculo + summary.amounts.autoexpreso;
    });
  }

  const filterCallback = (filters) => {
    let finesToInclude = fines.items.map(f => ({ ...f, selected: false }));
    if (!filters.includes('Autoexpreso')) { finesToInclude = finesToInclude.filter(fine => fine.type !== 'AUTOEXPRESO') };
    if (!filters.includes('Vehículo')) { finesToInclude = finesToInclude.filter(fine => fine.type !== 'VEHICLE') };
    if (!filters.includes('Licencia')) { finesToInclude = finesToInclude.filter(fine => fine.type !== 'LICENSE') };
    if (filters.includes('Con Descuento')) { finesToInclude = finesToInclude.filter(fine => fine.surchargeAmount < 0) };
    if (filters.includes('Con Intereses')) { finesToInclude = finesToInclude.filter(fine => fine.surchargeAmount > 0) };

    const selectionAux = finesToInclude.filter(fine => finalSelection.finesId.includes(fine.id)).map(f => ({ ...f, selected: true }));
    const newFinesToInclude = Utils.removeDuplicatebyId([...finesToInclude, ...selectionAux]);

    selectionUpdate({ ...finalSelection, selectedCount: selectionAux.length, selectedRows: selectionAux });
    setFinesFiltered({ ...fines, items: [...newFinesToInclude] });
    setCheckedFilters([...filters]);
  }


  function selectionUpdate(selection) {
    const ids = selection.selectedRows.map((f) => f.id);
    let actualSelection = {
      finesAmount: 0, discountAmount: 0, surchargeAmount: 0, totalAmount: 0,
      selectedCounter: selection.selectedCount,
      finesId: [...ids]
    }

    const finesSelected = selection.selectedRows;

    finesSelected.forEach(fineSelected => {
      actualSelection.finesAmount += fineSelected.amount;
      actualSelection.totalAmount += fineSelected.amount + fineSelected.surchargeAmount;

      fineSelected.surchargeDetails.forEach(surcharge => {
        if (surcharge.value < 0) {
          actualSelection.discountAmount += surcharge.value;
        } else {
          actualSelection.surchargeAmount += surcharge.value;
        }
      })
    });

    finesCallback(actualSelection);
    setSelectionModelArray([...ids]);
  }

  const ownerCallback = (userInfo, finesInfo) => {
    setUser(userInfo);
    updateFines(finesInfo);
    setFinalSelection(initialFinalSelection);
    setNextStep('finesPanel');
  };

  const finesCallback = (finalSelection, nextStep = false) => {
    if (finalSelection) {
      setFinalSelection(finalSelection);
    }
    if (nextStep) {
      setNextStep(nextStep);
      const finesSelected = finesFiltered.items.map(userFine => selectionModelArray.includes(userFine.id) ? { ...userFine, selected: true } : { ...userFine, selected: false });
      setFinesFiltered({ ...finesFiltered, items: finesSelected });
    }
  };

  const confirmationCallback = () => {
    clearSesion();
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
  }, [nextStep]);

  return (
    <div className="container">
      <CircularProgress
        variant="indeterminate"
        className="hide-loading"
        id="circular-loading"
        size={75}
        thickness={4}
      />
      <Router>
        <Header userData={user} setStep={setNextStep} step={nextStep} handleLogout={clearSesion} />
        <Switch>
          <Route exact path="/" render={(routeProps ) => <UserForm {...routeProps} currentStepper={<CustomStepper currentStep={0} currentComplete={{ 0: true }} />} ownerCallback={ownerCallback} clearSesion={confirmationCallback} /> } />
          <Route exact path="/seleccion" render={(routeProps ) => (finesFiltered) ? (<FinesPanel {...routeProps} fines={finesFiltered} totals={summary}
              finalSelection={finalSelection} finesCallback={finesCallback} filterCallback={filterCallback} pageSelectionUpdate={selectionUpdate}
              selectionModelArray={selectionModelArray} checkedFilters={checkedFilters} />) : <Redirect to="/" /> } currentStepper={<CustomStepper currentStep={1} currentComplete={{ 0: true, 1: true }} />} />
          <Route exact path="/pago" render={(routeProps ) => (finalSelection.totalAmount) ? (<PaymentForm totals={summary} {...routeProps} finalSelection={finalSelection} finesCallback={finesCallback} 
              transactionCallback={setTransaction} clearSesion={clearSesion} currentStepper={<CustomStepper currentStep={2} currentComplete={{ 0: true, 1: true, 2: true }} />} />) : <Redirect to="/" /> } />          
          <Route exact path="/confirmacion" render={(routeProps ) => (transaction) ? (<PaymentConfirmation totals={summary} {...routeProps} confirmationCallback={confirmationCallback} 
              transactionCode={transaction} />) : <Redirect to="/" /> } />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
