import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Warning } from "@material-ui/icons";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const AeTooltip = (props) => {
  return (
    <LightTooltip
      placement="right"
      arrow
      title={<span>Por favor diríjase a <b>www.autoexpreso.com</b> para ponerse al día con sus obligaciones</span>}
    >
      <Warning style={{ color: "#FAD271" }} fontSize="small" />
    </LightTooltip>
  );
};

export default AeTooltip;
